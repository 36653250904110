/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('roboto/roboto-v30-latin-300.woff2') format('woff2'), url('roboto/roboto-v30-latin-300.woff') format('woff');
}
/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('roboto/roboto-v30-latin-300italic.woff2') format('woff2'), url('roboto/roboto-v30-latin-300italic.woff') format('woff');
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('roboto/roboto-v30-latin-regular.woff2') format('woff2'), url('roboto/roboto-v30-latin-regular.woff') format('woff');
}
/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('roboto/roboto-v30-latin-italic.woff2') format('woff2'), url('roboto/roboto-v30-latin-italic.woff') format('woff');
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('roboto/roboto-v30-latin-500.woff2') format('woff2'), url('roboto/roboto-v30-latin-500.woff') format('woff');
}
/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('roboto/roboto-v30-latin-500italic.woff2') format('woff2'), url('roboto/roboto-v30-latin-500italic.woff') format('woff');
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('roboto/roboto-v30-latin-700.woff2') format('woff2'), url('roboto/roboto-v30-latin-700.woff') format('woff');
}
/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('roboto/roboto-v30-latin-700italic.woff2') format('woff2'), url('roboto/roboto-v30-latin-700italic.woff') format('woff');
}
